import Grid from '@mui/material/Grid';
import {
  OnDemandExperienceSummaryCard
} from '@paddl/utils-react';
import React, { useEffect, useState } from 'react';
import {
  shape,
  arrayOf, number,
  string
} from 'prop-types';
import { getMatchingExperiences, getMatchingPrograms } from './SearchAlgorithm';
import { ProgramSection_NEON } from './ProgramSection_NEON';

const renderExperiences = (experiences, completedExperiences) => {
  return experiences &&
      experiences.map(({
        id, title, providerId, activityType, duration
      }) =>
        <Grid key={id} item xs={12} sm={6} md={6} lg={4}>
          <OnDemandExperienceSummaryCard
            id={id}
            title={title}
            handleSeeMore={() => { window.location.href = `${window.location.href}/experience/${id}`; }}
            providerId={providerId}
            activityType={activityType}
            duration={duration}
            isComplete={completedExperiences.includes(id)}
          />
        </Grid>);
};

export const SearchResults = ({
  searchQuery, experiences, programs, completedExperiences
}) => {
  const [experiencesToDisplay, setExperiencesToDisplay] = useState([]);
  const [searchResults, setSearchResults] = useState({ experiences: [], programs: [] });
  const [displayMore, setDisplayMore] = useState(false);

  useEffect(() => {
    if (!displayMore || experiencesToDisplay.length === searchResults.experiences.length) return;
    setExperiencesToDisplay([...experiencesToDisplay, searchResults.experiences[experiencesToDisplay.length]]);
    setTimeout(() => {
      setDisplayMore(true);
    }, 100);
    setDisplayMore(false);
  }, [experiencesToDisplay, searchResults, displayMore]);

  useEffect(() => {
    setExperiencesToDisplay([]);
    if (!searchQuery) {
      setSearchResults(
        {
          experiences: [],
          programs: []
        }
      );
    }
    if (searchQuery) {
      setSearchResults(
        {
          experiences: getMatchingExperiences(experiences, searchQuery),
          programs: getMatchingPrograms(programs, searchQuery)
        }
      );
    }
    setDisplayMore(true);
  }, [experiences, programs, searchQuery]);

  return (
      <>
    <Grid container spacing={2}>
      {renderExperiences(experiencesToDisplay, completedExperiences)}
    </Grid>

  <ProgramSection_NEON programs={searchResults.programs} />
      </>);
};

SearchResults.propTypes = {
  searchQuery: string.isRequired,
  completedExperiences: arrayOf(number).isRequired,
  experiences: arrayOf(shape({})).isRequired,
  programs: arrayOf(shape({})).isRequired

};
