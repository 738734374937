import styled from '@emotion/styled';

import {
  Artworks,
  Button,
  Colors,
  FeedFooter,
  Fonts,
  InnerContentWrapper,
  MainContentWrapper,
  ModularPage,
  PageColumn
} from '@paddl/storybook';
import {
  hardRedirect,
  packagePrefixes
} from '@paddl/utils-js';
import {
  MemberAppNavigation_Populated_NEON,
  Metadata,
  useResource,
  useLoginContext
} from '@paddl/utils-react';
import React, { useEffect, useState, useMemo } from 'react';
import { SearchBar } from '../components/SearchBar';
import { SearchResults } from '../components/SearchResults';

import { ExperienceSection } from '../components/ExperienceSection';
import { ProgramSection_NEON } from '../components/ProgramSection_NEON';
import { XpCollection } from '../components/XpCollection';

const breadcrumbItems = [{ title: 'Explore' }];

const Header = styled.div`
    ${Fonts.H3}
    padding-bottom: 8px;
`;

const HeaderContainer = styled.div`
    padding-bottom: 16px;
    border-bottom: 1px solid ${Colors.grey_lighter};
`;

const Subheader = styled.div`
    ${Fonts.body}
`;

function EmptyState() {
  return (
    <div style={{
      display: 'flex', flexDirection: 'column', gap: '24px', alignItems: 'center', padding: '32px 0px'
    }}
    >
      <Artworks.noExperiences />
      <div style={{ textAlign: 'center' }}>
        <Header>No Experiences Available</Header>
        <Subheader>It appears you don’t have any access to Paddl upskilling content.</Subheader>
      </div>
      <Button onClick={() => hardRedirect('newActivities')} variant="contained" color="purple">Track your Upskilling</Button>
    </div>
  );
}

export function _ExplorePage() {
  const { isLoading: isLearnSomethingNewLoading, data: learnSomethingNewData } = useResource(`${process.env.AT_WORK_API}/v2/explore/learn-something-new?limit=12`);
  const { isLoading: isGoDeeperLoading, data: goDeeperData } = useResource(`${process.env.AT_WORK_API}/v2/explore/go-deeper?limit=12`);
  const [searchQuery, setSearchQuery] = useState('');
  const { data: completionData } = useResource(`${process.env.API_PREFIX_AT_WORK}/at-work/experiences/complete`);
  const completedExperiences = completionData?.completedExperiences || [];
  const { data: programsData, isLoading: isProgramsLoading } = useResource(`${process.env.AT_WORK_API}/v2/explore/programs`);
  const { data: allExperiencesData, isLoading: isAllExperiencesLoading } = useResource(`${process.env.AT_WORK_API}/v2/explore/on-demand`);

  useEffect(() => {
    if (window?.analytics) {
      window.analytics.page('Explore App', 'Explore');
    }
  }, []);

  const { profileId } = useLoginContext();

  const isLoading = isProgramsLoading || isGoDeeperLoading || isLearnSomethingNewLoading;

  const { allExperiences, newExperiences, deepExperiences } = useMemo(() => {
    const _newExperiences = learnSomethingNewData?.experiences || [];
    const _deepExperiences = goDeeperData?.experiences || [];
    const _allExperiences = allExperiencesData?.experiences || [];

    return {
      allExperiences: _allExperiences,
      deepExperiences: _deepExperiences,
      newExperiences: _newExperiences
    };
  }, [allExperiencesData?.experiences, goDeeperData?.experiences, learnSomethingNewData?.experiences]);

  const isEmpty = !isLoading && allExperiences.length === 0 && (programsData?.programs || []).length === 0;

  return (
      <MainContentWrapper colorVariant="dark" withGhostNav>
      {profileId && <XpCollection profileId={profileId} />}
      <Metadata title="Explore Experiences | Paddl" />
      <InnerContentWrapper size="large">
        <PageColumn>
        <HeaderContainer>
          <SearchBar onSearch={setSearchQuery} />
        </HeaderContainer>
        {isEmpty && <EmptyState />}
        {!isAllExperiencesLoading && searchQuery &&
          <SearchResults
            completedExperiences={completedExperiences}
            searchQuery={searchQuery}
            experiences={allExperiences}
            programs={programsData?.programs || []}
          />}
        {!searchQuery &&
          <ExperienceSection
            experiences={newExperiences}
            isLoading={isLearnSomethingNewLoading}
            totalCount={learnSomethingNewData?.totalCount}
            title="Learn Something New"
            showAllLink={`${packagePrefixes[process.env.NODE_ENV].exploreApp}/explore/learn-something-new`}
          />}
        {!searchQuery &&
          <ExperienceSection
            experiences={deepExperiences}
            isLoading={isGoDeeperLoading}
            totalCount={goDeeperData?.totalCount}
            title="Go Deeper"
            showAllLink={`${packagePrefixes[process.env.NODE_ENV].exploreApp}/explore/go-deeper`}
          />}

        {!searchQuery && <ProgramSection_NEON programs={programsData?.programs || []} isLoading={isProgramsLoading} /> }

        <FeedFooter />
        </PageColumn>
      </InnerContentWrapper>
      </MainContentWrapper>
  );
}

export const ExplorePage = () => <ModularPage
  navigationContent={<MemberAppNavigation_Populated_NEON currentPage="explore" />}
  breadcrumbItems={breadcrumbItems}
  pageName="explore"
  pageContent={<_ExplorePage />}

/>;
